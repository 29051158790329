<template>
  <div class="company-tabs" id="story">
    <img id="rictangleCompany" src="@/assets/img/rictangle.png" alt="" />

    <b-container>
      <b-row class="tabs">
        <b-col cols="12" md="4" class="tabs_menu">
          <div class="absolute-text_canvas">
            <h2 class="absolute-text">{{ data[activeSlide].bgText }}</h2>
          </div>

          <p class="tabs_title">Выберите интерсующий блок</p>
          <!-- <p class="tabs_subtitle">
            Только профессиональные решения для клиентов
          </p> -->

          <button
            v-bind:class="[index == activeSlide ? 'active' : '', 'tab-link']"
            v-for="(item, index) in data"
            v-bind:key="index"
            @click="putInfo(index)"
          >
            {{ item.slideTitle }}
          </button>

          <div class="absolute-text-bg"></div>
        </b-col>

        <b-col cols="12" md="8" class="tabs_content">
          <b-row>
            <b-col cols="12">
              <h2 class="slideTitle">
                {{ data[activeSlide].slideTitle }}
              </h2>
            </b-col>
          </b-row>

          <b-row class="slide-images" v-if="data[activeSlide].img">
            <b-col
              cols="12"
              md="4"
              v-for="(img, index) in data[activeSlide].img"
              :key="index"
            >
              <div class="slide_img-canvas">
                <img
                  class="slide_img"
                  :src="require('@/assets/photo/' + img)"
                  alt=""
                />
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <p v-html="data[activeSlide].text"></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "CompanyInfo",
  data() {
    return {
      data: [
        {
          slideTitle: "История создания",
          bgText: "ИсторияИсторияИсторияИсторияИсторияИсторияИстория",
          img: ["_S__3131.jpg", "_S__3108.jpg", "_S__3471.jpg"],
          text: `
          Дата основания: 6 мая 2020 г.<br />
          <br />
2020 год показал, что нужно быть мобильным, быстрым и смело адаптироваться к любым внешним обстоятельствам.
Компания ООО «С-МАТЕРИАЛС» появилась в разгар пандемии. Опыт учредителей, как со стороны заказчика, так и со стороны поставщика позволил выстоять компании в этот период.
Сегодня мы выстраиваем прочные доверительные отношения со всеми участниками процесса: с заказчиками, с поставщиками, с сотрудниками. Мы стабильно растём и развиваемся.<br />
<br />
Мы сотрудничаем с 19 проверенными компаниями-партнёрами. Для гарантированного закрытия любой потребности постоянно расширяется спектр товаров и услуг.<br />
<br />
За 2020 год мы выполнили 102 контракта на общую сумму 24 567 362,84 руб. В 2021 году показатели выросли до ХХ контрактов на общую сумму 109 665 446,03 руб. А в 2022 году мы выполнили уже 248 контрактов на общую сумму в 390 130 807,11 рублей!<br />
<br />
Также с 2020 года в нашей компании выросло число сотрудников – теперь в нашем коллективе 61 человек.
          `,
        },
        {
          slideTitle: "Цель, миссия, ценности",
          bgText:
            "ЦельМиссияЦенностиЦельМиссияЦенностиЦельМиссияЦенностиЦельМиссияЦенностиЦельМиссияЦенностиЦельМиссияЦенностиЦельМиссияЦенностиЦельМиссияЦенности",
          img: ["_S__3496.jpg", "_S__3088.jpg", "_S__3260.jpg"],
          text: `
            Цель С-МАТЕРИАЛС – стать лидером на рынке тендерных поставок в России по объёму продаж, числу крупных федеральных заказчиков и уровню сервиса оказываемых услуг.<br />
            <br />
            Наша мотивация – возможность нести пользу и видеть результат своего труда.<br />
            <br />
            Наша миссия:<br />
            - помочь малому и среднему российскому бизнесу правильно использовать потенциал рынка тендеров для уверенного и быстрого развития;<br />
            - Помочь в достижении поставленных целей крупному российскому бизнесу, эффективно оказывая услуги комплексного тендерного снабжения;<br />
            - помочь в раскрытии потенциала каждого сотрудника, создавая в компании экосистему гармоничной реализации человека.<br />
            <br />
            Наши ценности:<br />
            - постоянное развитие и рост;<br />
            - совершенствование процессов;<br />
            - гарантии выполнения работ;<br />
            - доверительные отношения с партнёрами;<br />
            - тёплые отношения в коллективе.<br />
            <br />
            С-МАТЕРИАЛС. С заботой и вниманием - надолго.<br />
          `,
        },
        {
          slideTitle: "Внутренние правила",
          bgText:
            "ПравилаПравилаПравилаПравилаПравилаПравилаПравилаПравилаПравилаПравила",
          img: ["_S__3295.jpg", "_S__3378.jpg", "IMAGE.png"],
          text: `
            <b>На чем строится работа</b><br />
            1. Польза. Каждый заказчик, партнер и сотрудник ощущает полезность, которую создает компания. Фундаментом внутреннего и внешнего сервиса является забота:<br />
            •	мы вкладываемся в развитие сотрудников. Нам важно, чтобы компания позволяла каждому участнику команды использовать и развивать свои сильные качества. Мы стремимся к созданию экологичного пространства, в котором сочетается профессиональная и личностная самореализация. Пространство, в котором любимая работа – это составная часть полноценной, счастливой жизни; <br />
            •	мы внимательны к каждому заказчику. Помогаем успешно решать любые задачи, соблюдая высокие стандарты качества;<br />
            •	мы помогаем компаниям, которые готовы соблюдать наши стандарты качества, увеличивать объемы продаж без траты времени на поиски новых каналов сбыта и клиентов.<br />
            <br />
            2.	Автоматизация процессов, развитие и совершенствование элементов внутренней среды компании: закупки, поставки, персонал, управления, маркетинг, финансы.<br />
            <br />
            3. Партнерство. Фокус на построение долгосрочных отношений, ведущих к развитию и росту каждой из сторон.<br />
            <br />
            4. Инициативность. Действуем на опережение и помогаем заказчику определить оптимальные варианты решения задачи.<br />
            <br />
            5. Легкость. Мы делаем все, чтобы работа проходила без напряжения.  <br />
            <br />
            6. Довольный клиент – критерий нашей эффективности.<br />
            <br />
            7. Надежность и качество. На нас можно положиться.<br />
            <br />

            <b>Чего не допускаем в делах</b><br />
            - посредственность в работе;<br />
            - безучастность;<br />
            - глупость и непрофессионализм;<br />
            - грубость в коммуникации;<br />
            - безответственность при решении задач;<br />
            - токсичность.<br />
            `,
        },
        {
          slideTitle: "Сотрудники, семья, традиции ",
          bgText:
            "СотрудникиСотрудникиСотрудникиСотрудникиСотрудникиСотрудникиСотрудникиСотрудникиСотрудникиСотрудникиСотрудникиСотрудникиСотрудники",
          img: ["_S__3164.jpg", "_S__3422.jpg", "_S__3563.jpg"],
          text: `
          Каждый участник команды ценен. Мы создаем условия и возможности развить компетенции и выйти на желаемый уровень дохода, при этом осознавая важность личной жизни человека. Мы стремимся, чтобы компания в жизни сотрудников стала гармоничной и желанной частью их образа жизни. Наши внутренние традиции позволяют выстроить экологичную и работоспособную обстановку. У нас приятно находиться и эффективно работать. Мы создаем условия для самореализации, в которых каждый сотрудник дает компании максимально возможную ценность.
          `,
        },
        {
          slideTitle: "Опыт основателей",
          bgText:
            "ОпытОснователейОпытОснователейОпытОснователейОпытОснователейОпытОснователейОпытОснователейОпытОснователейОпытОснователейОпытОснователейОпытОснователейОпытОснователей",
          img: ["_S__3078.jpg", "_S__3127.jpg", "_S__3311.jpg"],
          text: `<b>Константин Миронов- 10-летний опыт со стороны заказчика:</b><br>
          2010-2020 гг. - Дочерняя компания ПАО «НК Роснефть». Прошёл путь от сотрудника до руководителя проекта строительства систем автоматизации и связи.<br>
          `,
        },
        {
          slideTitle: "Учредительные документы",
          bgText: `ДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументыДокументы`,
          text: `
          <a class="document_link" href="https://disk.yandex.ru/i/MfSIyFMrSkEq8Q" target="_blank">ИНН</a><br />
          <a class="document_link" href="https://disk.yandex.ru/i/EHAhL4PNK_W-0w" target="_blank">ОГРН</a><br />
          <a class="document_link" href="https://disk.yandex.ru/i/LW4wztm6FwRxgQ" target="_blank">ЕГРИЛ</a><br />
          <a class="document_link" href="https://disk.yandex.ru/i/npAe3tC-2HLxPw" target="_blank">Устав</a><br />
          <a class="document_link" href="https://disk.yandex.ru/i/bugQsoHo1Yy7oA" target="_blank">Лицензия СРО</a><br />
          <a class="document_link" href="https://disk.yandex.ru/i/q1YziPMzEuE0yg" target="_blank">Сертификат Русклимат</a><br />
          `,
        },
      ],
    };
  },
  computed: {
    activeSlide: {
      get: function () {
        let num = 0;
        if (this.$route.query.slide) num = this.$route.query.slide;

        return num;
      },
    },
  },
  methods: {
    putInfo(num) {
      this.$router.push({
        path: "/company",
        query: { slide: num },
        hash: "#store",
      });
    },
  },
};
</script>

<style lang="scss">
#rictangleCompany {
  height: 140vh;
  position: absolute;
  left: 100%;
  top: 80%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

/* tabs */
.company-tabs {
  position: relative;
  padding-bottom: 100px;
}
.tabs {
  background-color: $gray;
  min-height: 60vh;
  position: relative;
  z-index: 2;
}
.tabs_menu {
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: $gray-light;
  padding: 30px;
  position: relative;
  overflow: hidden;
  position: relative;
}
.absolute-text_canvas {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  max-width: 135%;
  z-index: 0;
}
.absolute-text {
  bottom: 0;
  left: 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 130px;
  color: rgba($gray-light, 0.1);
  line-height: 120px;
  font-weight: 700;
  word-wrap: break-word;
  position: relative;
}
.absolute-text-bg {
  height: 350px;
}

.tabs_content {
  border-width: 1px;
  border-style: solid;
  border-color: $gray-light;
  padding: 30px;
}
.tab-link {
  display: block;
  background-color: rgba($black, 0);
  border: none;
  color: $white;

  font-size: 20px;
  font-weight: 400;

  margin-bottom: 10px;
  padding: 0;
  position: relative;
  z-index: 1;
}
.tab-link.active {
  color: $accent;
  font-weight: 500;
}
#slideContent {
  color: $white;
  transition: linear 700ms;
}
.tabs_title {
  color: $white;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
}
.tabs_subtitle {
  color: rgba($white, 0.5);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 30px;
}
.slideTitle {
  color: $white;
  font-size: 24px;
  margin: 0;
  padding-bottom: 30px;
}
.slide-images {
  padding-bottom: 30px;
}
.slide_img-canvas {
  height: 164px;
  background-color: $gray;
  border-radius: 3px;
  position: relative;
}
.slide_img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  border-radius: 3px;

  transition: linear 700ms;
}

.document_link {
  color: $white;
  margin-bottom: 10px;
  padding: 15px;
}
</style>
