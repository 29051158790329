<template>
  <div>
    <div class="navbar-canvas"></div>

    <HeaderLogo
      showMap
      title="КОМПАНИЯ С-МАТЕРИАЛС"
      text="Наша цель – стать лидером на рынке тендерных поставок в России по объёму продаж, числу крупных заказчиков и уровню сервиса оказываемых услуг."
      logo="logo.png"
    />

    <CompanyInfo />

    <ContactForm />

    <LineInfo
      title="Дополнительная информация"
      description="Держим в курсе актуальных новостей"
    />

    <MoreInfo />

    <!-- <News /> -->
  </div>
</template>

<script>
import LineInfo from "@/components/LineInfo.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import ContactForm from "@/components/ContactForm.vue";
import News from "@/components/News.vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
import CompanyInfo from "@/components/CompanyInfo.vue";

export default {
  name: "Company",
  components: {
    LineInfo,
    MoreInfo,
    ContactForm,
    News,
    HeaderLogo,
    CompanyInfo,
  },
};
</script>
